import { FirebaseError } from '@firebase/app';
import { AddressDocument } from '@models/address';
import {
  OrderDocument,
  OrderItemDocument,
  OrderItemInput,
  orderItemSchema,
} from '@models/order';
import { UserDocument } from '@models/user';
import { BreadcrumbItem } from '@ui/Breadcrumb';
import {
  CreatePaymentArgs,
  MinimalOrderDocument,
  UpdatePaymentArgs,
} from '@util/firestore/payments/payments.types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { CategoryDocument } from 'models/category';
import { ProductDocument, Variation } from 'models/product';
import { Bike, RideType, Vehicle } from 'models/shared';
import sanitize from 'sanitize-html';
import { getDiscount } from './firestore/cart';
import { getOrderId, getOrderNum } from './firestore/orders';
import {
  getProductById,
  getSizeFromVariation,
  getVariationPrice,
} from './firestore/products';
import { logError } from './logError';
import { CartItem, cartItemSchema } from './types/firestore/carts';
import { Coupon } from 'models/coupon';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const IMAGE_PATH = process.env.NEXT_PUBLIC_IS_STAGING
  ? 'https://mxlocker-staging.b-cdn.net/'
  : 'https://cdn.mxlocker.com/';
export const IMAGE_SIZE = '1920x2560';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getCartItemFromProductDocument = (
  product: ProductDocument,
  selectedVariations?: Variation[] | null,
  attribution?: OrderItemDocument['attribution']
): CartItem => {
  const item: CartItem = {
    product_id: product.id,
    product_title: product.title,
    product_image: product.thumbnail,
    product_link: product.slug,
    product_cost: selectedVariations
      ? getVariationPrice(product, selectedVariations)
      : product.price,
    seller_id: product.seller_id,
    qty: 1,
    account_id: product.account_id,
    is_flat_rate: product.is_flat_rate,
    ...(selectedVariations && {
      size: getSizeFromVariation(selectedVariations),
    }),
    ...(selectedVariations?.find((v) => v.color) && {
      color: selectedVariations.find((v) => v.color)!.color as string,
    }),
    attribution: attribution ?? {},
  };
  const res = cartItemSchema.safeParse(item);
  if (!res.success) {
    logError(res.error, 'getCartItemFromProductDocument');
    return item;
  }
  return res.data;
};

export const cartItemToProductDocument = async (
  item: CartItem
): Promise<ProductDocument | null> => {
  return getProductById(item.product_id);
};

export const cartItemToOrderItem = (item: CartItem): OrderItemDocument => {
  const hasVariations = item.size?.letter || item.size?.number || item.color;
  const orderItem: OrderItemInput = {
    id: item.product_id,
    seller_id: item.seller_id,
    qty: item.qty,
    state: 0,
    product_cost: item.product_cost,
    product_name: item.product_title,
    product_tax: item.tax ?? 0,
    buyer_shipping_cost: item.shipping_cost ?? 0,
    account_id: item.account_id,
    rate_id: item.rate_id,
    ...(hasVariations && {
      variations: {
        ...(item.size?.letter && { letter: item.size.letter }),
        ...(item.size?.number && { number: item.size.number }),
        ...(item.color && { color: item.color }),
      },
    }),
    attribution: item.attribution ?? {},
  };
  const res = orderItemSchema.safeParse(orderItem);
  if (!res.success) {
    logError(res.error, 'cartItemToOrderItem', item.product_id);
    return orderItem as OrderItemDocument;
  }
  return res.data;
};

export function formatAddress(address: AddressDocument): string {
  return `${address.address_line1}, ${address.city_locality} ${address.state_province} ${address.postal_code}, ${address.country_code}`;
}

const metaData = (order: MinimalOrderDocument) => {
  const items = Object.keys(order.sellers!).reduce((acc, key) => {
    order.sellers![key].forEach((item) => {
      (item as any).seller_id = key;
      const itemCopy = { ...item };
      delete itemCopy.product_name;
      acc[item.id] = JSON.stringify(itemCopy);
      return acc;
    });
    return acc;
  }, {} as { [seller_id: string]: string });
  return {
    ...items,
    order_id: order.id ?? '',
    buyer_id: order.buyer_id,
    from_web: 'true',
    ...(order.donate && { donation: 'R2R' }),
    ...(order.coupon?.id && { promo: order.coupon?.id }),
    ...(order.affirm_fee && { affirm_fee: order.affirm_fee.toFixed(2) }),
    ...(order.ra_fee && { ra_fee: order.ra_fee.toFixed(2) }),
  };
};

export async function getUpdatePaymentIntentArgs({
  pi_id,
  address,
  items,
  userDoc,
  coupons,
  tax,
  donationAmount = 0,
  is_affirm,
  ra_fee,
}: {
  coupons: Coupon[];
  pi_id: string;
  address: AddressDocument;
  items: Array<CartItem & { rate_id?: string }>;
  userDoc: UserDocument;
  tax: number;
  donationAmount?: number;
  is_affirm: boolean;
  ra_fee: number;
}): Promise<UpdatePaymentArgs> {
  const id = getOrderId();
  if (!id) throw new Error('Could not get order id');
  const itemsTotal = items.reduce((acc, item) => {
    return acc + item.product_cost * item.qty;
  }, 0);
  const discountTotal = getDiscount(itemsTotal, coupons, items);

  const c = coupons?.[0]; // just using 1 coupon for MVP
  const coupon: OrderDocument['coupon'] = c?.id
    ? {
        id: c.id,
        code: c.code,
        off: discountTotal,
        ...(c?.seller_id && { seller_id: c.seller_id }),
        ...(c?.account_id && { account_id: c.account_id }),
      }
    : undefined;

  const sellers = items.reduce<{ [key: string]: OrderItemDocument[] }>(
    (acc, item) => {
      const orderItem = cartItemToOrderItem(item);
      orderItem.product_tax = item.tax ?? 0;
      if (acc[item.seller_id]) {
        acc[item.seller_id].push(orderItem);
      } else {
        acc[item.seller_id] = [orderItem];
      }
      return acc;
    },
    {}
  );

  const item_count = items.reduce((acc, item) => acc + item.qty, 0);

  const subtotal = itemsTotal - discountTotal;

  const shippingTotal = items.reduce((acc, item) => {
    return acc + (item.shipping_cost ?? 0);
  }, 0);

  const total_after_tax =
    subtotal + shippingTotal + tax + donationAmount + ra_fee;
  const affirm_fee = is_affirm ? total_after_tax * 0.03 : 0;
  const total = total_after_tax + affirm_fee;
  // get temp order_num so zod parse doesn't fail (backend still hasn't finished)
  const order_num = await getOrderNum();
  const order = {
    affirm_fee,
    address,
    buyer_id: userDoc.uid,
    coupon,
    customer_id: userDoc.customer_id!,
    donate: !!donationAmount,
    id,
    order_num,
    item_count,
    payment: { type: 0, saved: true },
    product_ids: items.map((item) => item.product_id),
    sellers,
    shipping_total: shippingTotal,
    subtotal,
    total,
    tax,
    ra_fee,
  } satisfies MinimalOrderDocument;

  return {
    pi_id,
    address,
    amount: total,
    order,
    metadata: metaData(order),
  };
}

export function getCreatePaymentIntentArgs({
  items,
  userDoc,
}: {
  items: CartItem[];
  userDoc: UserDocument;
}): CreatePaymentArgs {
  const sellers = Object.fromEntries(
    items.map((item) => [item.seller_id, [cartItemToOrderItem(item)]])
  );
  let calculatedTotal = 0;
  items.forEach((item) => {
    calculatedTotal += item.product_cost * item.qty + (item.shipping_cost ?? 0);
  });
  const item_count = items.reduce((acc, item) => acc + item.qty, 0);

  // not including all of the Order fields, only the ones needed for creating a payment intent
  const order = {
    sellers,
    total: calculatedTotal,
    subtotal: calculatedTotal,
    shipping_total: 0,
    item_count,
    buyer_id: userDoc.uid,
    address: userDoc.addresses?.[0],
    customer_id: userDoc.customer_id,
    payment: { type: 0, saved: true },
    product_ids: items.map((item) => item.product_id),
  } as unknown as OrderDocument;
  return {
    amount: calculatedTotal,
    order,
  };
}

export function formatCurrency(number: number, currency = 'USD') {
  const upper = currency.toUpperCase();
  if (upper === 'CAD')
    return (
      new Intl.NumberFormat(undefined, {
        currency: 'USD',
        style: 'currency',
      }).format(number) + ' CAD'
    );
  return new Intl.NumberFormat(undefined, {
    currency: upper,
    style: 'currency',
  }).format(number);
}

export function formatTimestamp(timestamp?: number, hideTime = false) {
  if (!timestamp) return '';
  if (timestamp.toString().length === 10) timestamp *= 1000;
  return new Date(timestamp).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: hideTime ? undefined : 'numeric',
    minute: hideTime ? undefined : 'numeric',
    hour12: hideTime ? true : undefined,
  });
}
export function formatTime(timestamp: number) {
  const date = new Date(timestamp);
  return date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
  });
}

export function formatAuthError(error: FirebaseError) {
  const words = error.code.replace('auth/', '').replaceAll('-', ' ').split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}

export function formatMessage(msg: string) {
  if (msg && typeof msg === 'string') {
    const regex =
      /\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/gi;
    const matches = msg.match(regex);
    if (matches?.length) {
      matches.forEach((match) => {
        msg = msg.replace(
          match,
          `<a href="${match}" target="_blank">${match}</a>`
        );
      });
      return { html: sanitize(msg) };
    }
  }
  return { html: msg };
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function getHostUrl() {
  if (process.env.NODE_ENV === 'production') {
    const host = process.env.NEXT_PUBLIC_IS_STAGING
      ? 'mxlocker.dev'
      : 'mxlocker.com';
    return `https://${host}`;
  }
  if (process.env.NODE_ENV === 'development') return 'http://localhost:3000';
  throw new Error('host not configured for current environment');
}

export function getErrorMessage(e: any): string {
  return (
    e.message?.message?.message || e.message?.message || e.message || e || ''
  );
}
export const CURRENCY_MAP = {
  usd: 'US Dollar',
  cad: 'Canadian Dollar',
  eur: 'Euro',
  aud: 'Australian Dollar',
  hkd: 'Hong Kong Dollar',
  mxn: 'Mexican Peso',
  nzd: 'New Zealand Dollar',
  nok: 'Norwegian Krone',
  sgd: 'Singapore Dollar',
  chf: 'Swiss Franc',
  thb: 'Thai Baht',
  gbp: 'British Pound',
} as const;

export function getSellerRegistrationCurrencyOptions(): {
  label: string;
  value: string;
  id: string;
}[] {
  const allCurrency = getSellerRegistrationCountries().map((c) => c.currency);
  return [...new Set(allCurrency)].sort().map((c) => ({
    label: CURRENCY_MAP[c],
    value: c,
    id: c,
  }));
}

export function getSellerRegistrationCountries() {
  const countries = [
    {
      code: 'US',
      name: 'United States',
      currency: 'usd',
    },
    {
      code: 'CA',
      name: 'Canada',
      currency: 'cad',
    },
    { code: 'AT', name: 'Austria', currency: 'eur' },
    {
      code: 'AU',
      name: 'Australia',
      currency: 'aud',
    },
    { code: 'BE', name: 'Belgium', currency: 'eur' },
    { code: 'BG', name: 'Bulgaria', currency: 'eur' },
    { code: 'HR', name: 'Croatia', currency: 'eur' },
    { code: 'CY', name: 'Cyprus', currency: 'eur' },
    {
      code: 'CZ',
      name: 'Czech Republic',
      currency: 'eur',
    },
    { code: 'DK', name: 'Denmark', currency: 'eur' },
    { code: 'EE', name: 'Estonia', currency: 'eur' },
    { code: 'FI', name: 'Finland', currency: 'eur' },
    { code: 'FR', name: 'France', currency: 'eur' },
    { code: 'DE', name: 'Germany', currency: 'eur' },
    { code: 'GR', name: 'Greece', currency: 'eur' },
    {
      code: 'HK',
      name: 'Hong Kong',
      currency: 'hkd',
    },
    { code: 'HU', name: 'Hungary', currency: 'eur' },
    { code: 'IE', name: 'Ireland', currency: 'eur' },
    { code: 'IT', name: 'Italy', currency: 'eur' },
    { code: 'LV', name: 'Latvia', currency: 'eur' },
    { code: 'LT', name: 'Lithuania', currency: 'eur' },
    { code: 'LU', name: 'Luxembourg', currency: 'eur' },
    { code: 'MT', name: 'Malta', currency: 'eur' },
    {
      code: 'MX',
      name: 'Mexico',
      currency: 'mxn',
    },
    { code: 'NL', name: 'Netherlands', currency: 'eur' },
    {
      code: 'NZ',
      name: 'New Zealand',
      currency: 'nzd',
    },
    {
      code: 'NO',
      name: 'Norway',
      currency: 'nok',
    },
    { code: 'PL', name: 'Poland ', currency: 'eur' },
    { code: 'PT', name: 'Portugal', currency: 'eur' },
    { code: 'RO', name: 'Romania', currency: 'eur' },
    {
      code: 'SG',
      name: 'Singapore',
      currency: 'sgd',
    },
    { code: 'SK', name: 'Slovakia', currency: 'eur' },
    { code: 'SI', name: 'Slovenia', currency: 'eur' },
    { code: 'ES', name: 'Spain', currency: 'eur' },
    { code: 'SE', name: 'Sweden', currency: 'eur' },
    {
      code: 'CH',
      name: 'Switzerland',
      currency: 'chf',
    },
    {
      code: 'TH',
      name: 'Thailand',
      currency: 'thb',
    },
    {
      code: 'GB',
      name: 'United Kingdom',
      currency: 'gbp',
    },
  ] as const;
  return countries;
}

export const offerStateToText = (state: number) => {
  switch (state) {
    case 1:
      return 'Accepted';
    case 2:
      return 'Declined';
    case 3:
      return 'Cancelled';
    default:
      return 'Pending';
  }
};

export const timeAgo = (timestamp?: number) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  if (!Intl || typeof Intl.RelativeTimeFormat !== 'function')
    return date.toLocaleString(); // unsupported browser
  const formatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
  const ranges: Partial<Record<Intl.RelativeTimeFormatUnit, number>> = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
    second: 1,
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (let key in ranges) {
    const range = key as Intl.RelativeTimeFormatUnit;
    if (ranges[range] && ranges[range]! < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[range]!;
      return formatter.format(Math.round(delta), range);
    } else if (key === 'second') return 'just now';
  }
};

export const businessDaysAgo = (timestamp?: number) => {
  if (!timestamp) {
    timestamp = Date.now();
  }

  let targetDate = dayjs(timestamp);
  targetDate.set('hours', 0);
  targetDate.set('minutes', 0);
  targetDate.set('seconds', 0);
  let now = dayjs();
  now.set('hours', 0);
  now.set('minutes', 0);
  now.set('seconds', 0);
  let businessDaysAgo = 0;

  while (targetDate < now) {
    if (targetDate.day() !== 0 && targetDate.day() !== 6) {
      businessDaysAgo++;
    }
    targetDate = targetDate.add(1, 'day');
  }

  return businessDaysAgo;
};

// Hours minutes and seconds until timestamp is reached in the format #H #M #S
export const timeRemaining = (timestamp?: number) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const secondsRemaining = (date.getTime() - Date.now()) / 1000;

  const { days, hours, minutes, seconds } =
    getTimeRemainingParts(secondsRemaining);

  const timeRemaining = `${
    days ? `${days}d ` : ''
  }${hours}h ${minutes}m ${seconds}s`;
  return timeRemaining;
};

export const getTimeRemainingParts = (secondsRemaining: number) => {
  const days = Math.floor(secondsRemaining / 86400);
  const hours = Math.floor((secondsRemaining % 86400) / 3600);
  const minutes = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = Math.floor(secondsRemaining % 60);
  return { days, hours, minutes, seconds };
};

export function isMobile() {
  return typeof window !== 'undefined' && window.innerWidth <= 768;
}

export function isTablet() {
  return typeof window !== 'undefined' && window.innerWidth <= 1024;
}

export function isDesktop() {
  return typeof window !== 'undefined' && window.innerWidth > 1024;
}

export function isLargeDesktop() {
  return typeof window !== 'undefined' && window.innerWidth > 1280;
}

export const debounce = (func: any, delay: number) => {
  let timerId: any;
  return function (this: any, ...args: any[]) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const capitalize = (word?: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const sentenceCase = (word?: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

// title case, could be multiple words
export const titleCase = (words?: string) => {
  if (!words) return '';
  return words
    .split(' ')
    .map((word) => sentenceCase(word))
    .join(' ');
};

export const diffInDaysFromNow = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return diff / (1000 * 3600 * 24);
};
export const timestampToDatetimelocal = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${year}-${
    month < 10 ? `0${month}` : month
  }-${day}T${hours}:${minutes}:${seconds}`;
};

export const datetimeLocalToTimestamp = (datetimeLocal: string) => {
  const date = new Date(datetimeLocal);
  return date.getTime();
};

export const getDurationInDays = (start: number, end: number): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();
  return Math.floor(diff / (1000 * 3600 * 24));
};

export const timestampFromDuration = (duration: number, start: string) => {
  const now = new Date(start);
  const timestamp = now.getTime() + duration * 1000 * 3600 * 24;
  return timestamp;
};

export const getEndDate = (duration: number, start: string) => {
  const date = new Date(timestampFromDuration(duration, start));
  // return date and time
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

export const getDatePartsFromTimestamp = (timestamp?: number) => {
  if (!timestamp) return;
  const date = new Date(timestamp);
  const hour = date.getHours();
  const minute = date.getMinutes();

  const hourIn12 = hour % 12;

  return {
    date: date.toISOString().split('T')[0],
    hour: hourIn12 < 10 ? `0${hourIn12}` : `${hourIn12}`,
    minute: minute < 10 ? `0${minute}` : `${minute}`,
    AMPM: hour < 12 ? 'AM' : 'PM',
  };
};

export const inBetween = (startTime: string, duration: string) => {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(timestampFromDuration(Number(duration), startTime));
  return now.getTime() > start.getTime() && now.getTime() < end.getTime();
};

export const getCDNUrl = (src: string) => {
  if (!src) return '';
  const baseURL =
    'https://firebasestorage.googleapis.com/v0/b/mxlocker-b1d4a.appspot.com/o/';
  if (src.includes(baseURL)) {
    src = src.replace(baseURL, 'https://cdn.mxlocker.com/');
    if (src.includes('image') && src.includes('thumb')) {
      const created: any = src.split('image')[1].slice(0, 13);
      const isNewFormat = !isNaN(created) && Number(created) >= 1663699424310;
      return isNewFormat && src.includes('?alt=media') && !src.includes('.webp')
        ? src.replace('?alt=media', '.webp?alt=media')
        : src;
    }
  }
  return src;
};

export const formatBike = (bike: Bike) => {
  return `${bike.year} ${bike.make} ${bike.model}`;
};

export const isEU = () => {
  dayjs.extend(timezone);
  const tz = dayjs.tz.guess();
  switch (tz) {
    case 'Europe/Vienna':
      return true;
    case 'Europe/Brussels':
      return true;
    case 'Europe/Sofia':
      return true;
    case 'Europe/Zagreb':
      return true;
    case 'Asia/Famagusta':
      return true;
    case 'Asia/Nicosia':
      return true;
    case 'Europe/Prague':
      return true;
    case 'Europe/Copenhagen':
      return true;
    case 'Europe/Tallinn':
      return true;
    case 'Europe/Helsinki':
      return true;
    case 'Europe/Paris':
      return true;
    case 'Europe/Berlin':
      return true;
    case 'Europe/Busingen':
      return true;
    case 'Europe/Athens':
      return true;
    case 'Europe/Budapest':
      return true;
    case 'Europe/Dublin':
      return true;
    case 'Europe/Rome':
      return true;
    case 'Europe/Riga':
      return true;
    case 'Europe/Vilnius':
      return true;
    case 'Europe/Luxembourg':
      return true;
    case 'Europe/Malta':
      return true;
    case 'Europe/Amsterdam':
      return true;
    case 'Europe/Warsaw':
      return true;
    case 'Atlantic/Azores':
      return true;
    case 'Atlantic/Madeira':
      return true;
    case 'Europe/Lisbon':
      return true;
    case 'Europe/Bucharest':
      return true;
    case 'Europe/Bratislava':
      return true;
    case 'Europe/Ljubljana':
      return true;
    case 'Africa/Ceuta':
      return true;
    case 'Atlantic/Canary':
      return true;
    case 'Europe/Madrid':
      return true;
    case 'Europe/Stockholm':
      return true;
    default:
      return false;
  }
};

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result + '';
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function capitalizeFirstLetter(string?: string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatRange(deliveryDates?: {
  earliest: string;
  latest: string;
}): string | null {
  if (!deliveryDates) return null;
  const { earliest, latest } = deliveryDates;
  const start = dayjs(earliest).add(3, 'days');
  const end = dayjs(latest).add(3, 'days');
  if (start.month() === end.month())
    return `${start.format('MMM DD')}-${end.format('DD')}`;
  return `${start.format('MMM DD')}-${end.format('MMM DD')}`;
}

export function isBot(userAgent: string) {
  return /Googlebot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Google-InspectionTool|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver|curl/i.test(
    userAgent
  );
}

export function keysDiff(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any },
  parentKey = '',
  ignoreNotCommon: boolean = false
): string[] {
  const keysWithDifferences: string[] = [];
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (ignoreNotCommon && !Object.prototype.hasOwnProperty.call(obj2, key)) {
        continue;
      }

      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        keysWithDifferences.push(
          ...keysDiff(obj1[key], obj2[key], currentKey, ignoreNotCommon)
        );
      } else if (
        !Object.prototype.hasOwnProperty.call(obj2, key) ||
        obj1[key] !== obj2[key]
      ) {
        keysWithDifferences.push(currentKey);
      }
    }
  }
  for (const key in obj2) {
    if (
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      !Object.prototype.hasOwnProperty.call(obj1, key)
    ) {
      if (ignoreNotCommon) {
        continue;
      }
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      keysWithDifferences.push(currentKey);
    }
  }
  return keysWithDifferences;
}

export function getBreadcrumbItems(
  category: CategoryDocument
): BreadcrumbItem[] {
  return (
    category.breadcrumbs?.map((bc) => ({
      label: bc.name,
      href: bc.slug,
      current: bc.name === category.name,
    })) ?? []
  );
}

export function getCountryLabelFromCode(code: string) {
  switch (code) {
    case 'US':
      return 'United States';
    case 'CA':
      return 'Canada';
    case 'MX':
      return 'Mexico';
    case 'PR':
      return 'Puerto Rico';
    case 'EU':
      return 'Europe';
    case 'AU':
      return 'Australia';
    case 'SAM':
      return 'South America';
    default:
      return 'Unknown';
  }
}

export function rockyTypeToMXType(rockyType: Vehicle['type']) {
  const rockyTypeToMX: Record<Vehicle['type'], RideType> = {
    DIRT_BIKE: 'Dirt Bikes',
    ATV: 'ATV',
    UTV: 'UTV',
    STREET: 'Street',
    TRIALS: 'Dirt Bikes',
    DUAL_SPORT: 'Street',
  };
  return rockyTypeToMX[rockyType];
}
