// import * as Sentry from '@sentry/browser';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { initializeFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const clientCredentials = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(clientCredentials);
const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: process.env.NODE_ENV === 'production',
  experimentalAutoDetectLongPolling: true,
});
const AUTH = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);
const realtimeDatabase = getDatabase(firebaseApp);
const analyticsPromise = isSupported().then((yes) =>
  yes ? getAnalytics(firebaseApp) : null
);

const EMULATORS_STARTED = 'EMULATORS_STARTED';
function startEmulators() {
  if (!(global as any)[EMULATORS_STARTED]) {
    (global as any)[EMULATORS_STARTED] = true;
    // connectAuthEmulator(AUTH, 'http://localhost:9099');
    // connectFunctionsEmulator(functions, 'localhost', 5001);
    // connectFirestoreEmulator(db, 'localhost', 8080);
  }
}
if (process.env.NODE_ENV === 'development') {
  startEmulators();
}

if (
  typeof window !== 'undefined' &&
  !process.env.NEXT_PUBLIC_IS_STAGING &&
  process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY
) {
  if (process.env.NODE_ENV === 'development') {
    // check console for token, set it here and in firebase console
    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

enum FirebaseCallable {
  acceptOffer = 'v2_acceptOffer',
  addSellerPayoutMethod = 'v2_addSellerPayoutMethod',
  addToCart = 'v2_addToCart',
  addToFlashDeals = 'v2_addToFlashDeals',
  calculateMetrics = 'v2_calculateMetrics',
  chargeAccount = 'v2_chargeAccount',
  chargeUnpaidOrder = 'v2_chargeUnpaidOrder',
  checkRaEligibility = 'v2_checkRAEligibility',
  clearNotifications = 'v2_clearNotifications',
  completeRegistration = 'v2_completeRegistration',
  contactUs = 'v2_contactUs',
  createAndChargeLabel = 'v2_createAndChargeLabel',
  createCustomer = 'v2_createCustomer',
  createOfferIntent = 'v2_createOfferIntent',
  createOrderByCharge = 'v2_createOrderByCharge',
  createPaymentIntent = 'v2_createPaymentIntent',
  createPaymentIntentForLabel = 'v2_createPaymentIntentForLabel',
  createSeller = 'v2_createSeller',
  createSellerPromoCode = 'v2_createSellerPromoCode',
  createSetupIntent = 'v2_createSetupIntent',
  createTransfer = 'v2_createTransfer',
  createTransferOrder = 'v2_createTransferOrder',
  deleteCard = 'v2_deleteCard',
  deleteExternalAccount = 'v2_deleteExternalAccount',
  exportOrders = 'v2_exportOrderHistory',
  exportPayouts = 'v2_exportPayouts',
  fixLabel = 'v2_fixLabel',
  getBalance = 'v2_getBalance',
  getBankToken = 'v2_getBankToken',
  getCustomer = 'v2_getCustomer',
  getEstimates = 'v2_getEstimates',
  getExternalAccounts = 'v2_getExternalAccounts',
  getLabel = 'v2_getLabel',
  getPaymentInfo = 'v2_getPaymentInfo',
  getPaymentMethods = 'v2_getPaymentMethods',
  getPayoutInfo = 'v2_getPayoutInfo',
  getPromos = 'v2_getPromos',
  getRates = 'v2_getRates',
  getRecommendProducts = 'v2_getShapedProducts',
  getReturnLabel = 'v2_getReturnLabel',
  getSeelQuote = 'v2_getSeelQuote',
  getSeller = 'v2_getSeller',
  getShopOverview = 'v2_getShopOverview',
  getTax = 'v2_getTax',
  getUserByPhone = 'v2_getUserByPhone',
  googleAutocomplete = 'v2_googleAutocomplete',
  impersonateUser = 'v2_impersonateUser',
  initiateCheckout = 'v2_initiateCheckout',
  initiateReturn = 'v2_initiateReturn',
  isSubscribed = 'v2_isSubscribed',
  listTransactions = 'v2_listTransactions',
  makeDefaultExternalAccount = 'v2_makeDefaultExternalAccount',
  markAsReceived = 'v2_markAsReceived',
  markSellerListingsAsDraft = 'v2_markSellerListingsAsDraft',
  payoutSeller = 'v2_payoutSeller',
  payWithBalance = 'v2_payWithBalance',
  refundOrder = 'v2_refundOrder',
  removeExternalAccount = 'v2_removeExternalAccount',
  retrieveSellerPromoCodes = 'v2_retrieveSellerPromoCodes',
  revokeAppleToken = 'v2_revokeAppleToken',
  saveCard = 'v2_saveCard',
  setStoreOnSale = 'v2_setStoreOnSale',
  startTracking = 'v2_startTracking',
  subscribeToList = 'v2_subscribeToList',
  toggleSellerPromoCode = 'v2_toggleSellerPromoCode',
  trackPackage = 'v2_trackPackage',
  trackPackageGlobal = 'v2_trackPackageGlobal',
  trackUserInteraction = 'v2_trackUserInteraction',
  updateAuthUser = 'v2_updateAuthUser',
  updateOfferIntent = 'v2_updateOfferIntent',
  updatePaymentIntent = 'v2_updatePaymentIntent',
  updatePaymentIntentMethod = 'v2_updatePaymentIntentMethod',
  updateSellerType = 'v2_updateSellerType',
  updateStripeAccount = 'v2_updateStripeAccount',
  vacationMode = 'v2_vacationMode',
  validateAddress = 'v2_validateAddress',
  viewedProduct = 'v2_viewedProduct',
  voidLabel = 'v2_voidLabel',
  // u ship
  createListing = 'uship-v2_createListing',
  calculateBikeShipping = 'uship-v2_calculateBikeShipping',
  getListing = 'uship-v2_getListing',
  getTracking = 'uship-v2_getTracking',
}

export {
  AUTH,
  FirebaseCallable,
  analyticsPromise,
  db,
  functions,
  httpsCallable,
  realtimeDatabase,
  storage,
};
